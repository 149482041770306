export function useFontsCssCompanySet() {
  const { companyId } = storeToRefs(useStoreApp())

  const { brandings } = storeToRefs(useStoreBranding())
  useStoreBranding().fetchBrandings(companyId.value)

  const { applyCustomCss } = useCompanyCustomCss()

  watch(
    brandings,
    (newVal, oldVal) => {
      if (newVal) {
        const { branding_general: brandingGeneral } = newVal
        const brandingGeneralOld = oldVal?.branding_general

        setCompanyFonts(brandingGeneral, brandingGeneralOld)
        applyCustomCss(brandingGeneral, brandingGeneralOld)
      }
    },
    { immediate: true }
  )
}
