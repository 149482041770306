import type { BrandingGeneral } from '~/types/branding'

export function useCompanyCustomCss() {
  const applyCustomCss = (brandingGeneral: BrandingGeneral, oldBrandingGeneral: BrandingGeneral | undefined) => {
    const { custom_css: css } = brandingGeneral

    if (!oldBrandingGeneral && css) {
      useHead({ link: [{ rel: 'stylesheet', href: css }] })
      return
    }

    const oldCss = oldBrandingGeneral?.custom_css

    if (css && css !== oldCss) {
      useHead({ link: [{ rel: 'stylesheet', href: css }] })
    }

    if (!css && oldCss) {
      const selector = `link[href='${oldCss}']`
      const link = document.head.querySelector(selector)

      if (link) link.remove()
    }
  }

  return { applyCustomCss }
}
