import type { BrandingGeneral } from '~/types/branding'

export const getParameterByName = (name: string, url: string) => {
  if (!url) url = window.location.href
  name = name.replace(/[[\]]/g, '\\$&')
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)')
  const results = regex.exec(url)
  if (!results) return null
  if (!results[2]) return ''
  return decodeURIComponent(results[2].replace(/\+/g, ' ').split(':')[0])
}

export const setCompanyFont = (fontVarName: string, fontValue: string) => {
  const link = document.createElement('link')
  link.rel = 'stylesheet'
  link.href = fontValue
  document.documentElement.style.setProperty(fontVarName, getParameterByName('family', fontValue))
  document.getElementsByTagName('head')[0].appendChild(link)
}

export const setCompanyFonts = (brandingGeneral: BrandingGeneral, oldBrandingGeneral: BrandingGeneral | undefined) => {
  const { title_typography, text_typography } = brandingGeneral

  if (!oldBrandingGeneral) {
    setCompanyFont('--font-primary', title_typography)
    setCompanyFont('--font-secondary', text_typography)
    return
  }

  const { title_typography: oldTitleTypography, text_typography: oldTextTypography } = oldBrandingGeneral
  if (title_typography !== oldTitleTypography) {
    setCompanyFont('--font-primary', title_typography)
  }
  if (text_typography !== oldTextTypography) {
    setCompanyFont('--font-secondary', text_typography)
  }
}
